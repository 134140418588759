import { Excel } from 'antd-table-saveas-excel';
import { saveAs } from 'file-saver';
import { Toast } from 'antd-mobile';

export async function exportToExcel(options: { data: any; columns: any; filenamePrefix: string }) {
  const excel = new Excel();
  const file = excel.addSheet('sheet1').addColumns(options.columns).addDataSource(options.data).file;

  await file
    .saveAs('blob', false)
    .then((blob) => {
      const f = new File([blob], options.filenamePrefix + '.xlsx', {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      return saveAs(f, options.filenamePrefix + '.xlsx');
    })
    .catch((e) => {
      Toast.show('发生错误，请联系管理员');
      throw e;
    });
}

export async function readUsersFromExcel(file: File) {
  const fdata = await file.arrayBuffer();
  /* data is an ArrayBuffer */
  return import('xlsx').then(({ read, utils }) => {
    const workbook = read(fdata);
    const data = utils.sheet_to_json(workbook.Sheets['Sheet1']);
    return data;
  });
}
