import dayjs from 'dayjs';
import { useEffect } from 'react';
import { defineStore } from '@/hooks/defineStore';
import { hasLogin } from '@/utils/login';
import { IParameter, IUser } from '@/types';
import { serviceUserSelf } from '@/services/auth';

export interface IPageInfo {
  title: string;
}

interface IStore {
  loggedIn: boolean;
  parameters: IParameter;
  pageInfo?: IPageInfo;
  user?: IUser;
  hideBottomBar?: boolean;
  showBatchCreateUsers: boolean;
}

const defaultParameters: IParameter = {
  name: '',
  gender: 1,
  birthday: dayjs(new Date('1983-02-01')).format('YYYY-MM-DD'),
  startFoundedYear: 2006,
  restYearsToFound: 15,
  targetRetireAge: 60,
  yearsOfRetire: 20,
  insuranceBase: 20000,
  currentAverageSalary: 11396,

  personalFoundIndexLow: 150,
  personalFoundIndexMedium: 150,
  personalFoundIndexHigh: 150,

  socialSecurityInterestLow: 2.5,
  socialSecurityInterestMedium: 3.5,
  socialSecurityInterestHigh: 4.5,

  averageSalaryGrowthRateLow: 2.5,
  averageSalaryGrowthRateMedium: 3.5,
  averageSalaryGrowthRateHigh: 5.0,

  inflationRateLow: 2.0,
  inflationRateMedium: 3.0,
  inflationRateHigh: 3.8,

  selfSalaryGrowthRateLow: 2.5,
  selfSalaryGrowthRateMedium: 3.5,
  selfSalaryGrowthRateHigh: 5.0,

  ownedAccountGrowthRateLow: 0,
  ownedAccountGrowthRateMedium: 0,
  ownedAccountGrowthRateHigh: 0,

  baseOnSalaryRate: 70,

  salary: 20000,

  accountBalance: 180000,

  otherAccount: 2000000,
  percentageInOtherFoundedInsurance: 10,
  percentageInOtherFoundedCompanyAnnuity: 20
};

export const {
  hook: useStore,
  updateStore,
  readonlyStateRef: storeRef
} = defineStore<IStore, {}>(
  {
    loggedIn: hasLogin(),
    parameters: defaultParameters,
    showBatchCreateUsers: false
  },
  { name: 'AppStore' }
);

export function updateParameters(v: IParameter) {
  updateStore((st) => ({
    ...st,
    parameters: { ...defaultParameters, ...v }
  }));
}

export function resetParameters() {
  updateStore((st) => ({
    ...st,
    parameters: { ...defaultParameters }
  }));
}

export function setStoreUser(v: IUser) {
  updateStore((st) => ({
    ...st,
    user: v
  }));
}

export async function refetchUser() {
  const user = await serviceUserSelf();
  setStoreUser(user);
}

export function useInitUser() {
  useEffect(() => {
    refetchUser();
  }, []);
}

export function setPageInfo(v: IPageInfo) {
  updateStore((st) => ({
    ...st,
    pageInfo: v
  }));
}

window.__showBatchCreateUserBtn = () => {
  updateStore((st) => ({
    ...st,
    showBatchCreateUsers: true
  }));
};
