/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
export const whiteList = [/\/oauth/, /\/auth/, /\/share\/?/, /\/exception/];

export default function isAccessibleRoute() {
  return whiteList.some((path) => path.test(window.location.pathname));
}
