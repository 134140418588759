import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { History } from 'history';
import { goToLoginPage } from '@/utils/navigations';
import isAccessibleRoute from '@/routes/routesPermission';
import { updateStore } from '@/store/store';
import { routeStore } from '@/store/route';

export function getTokens() {
  const token = Cookies.get('token');
  console.log(token);
  if (!token) {
    return undefined;
  }
  return {
    token: Cookies.get('token')
  };
}

export function flushUserCookies() {
  Cookies.remove('token');
}

export function hasLogin() {
  return !!getTokens();
}

export function checkLogin() {
  const isLoggedIn = hasLogin();
  if (isLoggedIn) return true;
  return false;
}

export function checkPermission(): boolean {
  if (isAccessibleRoute()) {
    return true;
  }
  return checkLogin();
}

export function useCheckPermissionOnRouteChange() {
  const history = useHistory();
  useEffect(() => {
    function check() {
      const allow = checkPermission();
      console.log(allow);
      if (!allow) {
        goToLoginPage();
      }
    }

    check();

    history.listen(() => {
      check();
    });
  }, [history]);
}

/**
 * 检查登录态，并重定向至目标地址
 */
export async function checkLoginStateThenRedirectIfValid(history: History) {
  function getDefaultRedirectUrl(): string {
    const redirectUrl = new URL(window.location.href).searchParams.get('redirect') || window.location.origin;
    return redirectUrl;
  }

  if (!hasLogin()) {
    flushUserCookies();
  } else {
    const url = new URL(getDefaultRedirectUrl());
    history.replace(url.pathname + url.search);
    return;
  }
}

export function logout() {
  flushUserCookies();
  updateStore((st) => ({
    ...st,
    loggedIn: false,
    user: undefined
  }));
  goToLoginPage();
}
