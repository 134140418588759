import { routeStore } from '@/store/route';

export function goToLoginPage(params: { redirect?: string } = {}) {
  function getDefaultRedirectUrl() {
    return new URL(window.location.href).searchParams.get('redirect') || window.location.href;
  }

  const { redirect = getDefaultRedirectUrl() } = params;
  if (routeStore.value.history) {
    routeStore.value.history!.replace({
      pathname: `/auth`,
      search: `?redirect=${encodeURIComponent(redirect)}`
    });
    return;
  }
  window.location.replace('/auth' + `?redirect=${encodeURIComponent(redirect)}`);
}

export function goToFormPage() {
  routeStore.value.history?.push('/form');
}

export function goToHistoryPage() {
  routeStore.value.history?.push('/');
}

export function goToResultPage(id: number) {
  routeStore.value.history?.push('/result/' + id);
}
