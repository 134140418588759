import React, { useState, Suspense, useMemo, useEffect } from 'react';
import './App.less';
import { BrowserRouter, Switch, Route, useHistory, Redirect } from 'react-router-dom';
import { SpinLoading } from 'antd-mobile';
import BackTop from 'antd/es/back-top';
import { useInitAxios } from '@/services/request';
import { useCheckPermissionOnRouteChange } from '@/utils/login';

const AppHome = React.lazy(() => import('@/components-views/AppHome'));

const Auth = React.lazy(() => import('@/components-views/AppLogin'));
const AppParameterForm = React.lazy(() => import('@/components-views/AppParameterForm'));
const AppParameterList = React.lazy(() => import('@/components-views/AppParametersList'));
const AppResultSingle = React.lazy(() => import('@/components-views/AppResultSingle'));
const AppProfile = React.lazy(() => import('@/components-views/AppProfile'));

import AppBottomTabBar from '@/components-views/AppBottomTabBar';
import { updateRouteStore } from '@/store/route';
import { useInitUser, useStore } from '@/store/store';
import AppUsers from '@/components-views/AppUsers';
import AppBackground from '@/components-views/AppBackground';

const Suspense2 = (props: React.PropsWithChildren<any>) => {
  const loadingSimple = useMemo(
    () => (
      <div className="w-[100vw] h-[100vh] flex items-center justify-center">
        <SpinLoading />
      </div>
    ),
    []
  );
  useInitAxios();
  useInitUser();

  const getLoading = () => {
    return loadingSimple;
  };
  return <Suspense fallback={getLoading()}>{props.children}</Suspense>;
};

const context = React.createContext(null);

function AuthWrapper({ children }: { children: React.ReactNode }) {
  useCheckPermissionOnRouteChange();
  const history = useHistory();
  useEffect(() => {
    updateRouteStore((st) => ({
      ...st,
      history,
      location: history.location
    }));
    history.listen(() => {
      updateRouteStore((st) => ({
        ...st,
        location: history.location
      }));
    });
  }, [history]);
  return <context.Provider value={null}>{children}</context.Provider>;
}

function App() {
  return (
    <div className="App">
      <AppBackground />
      <BrowserRouter>
        <AuthWrapper>
          <Suspense2>
            <Switch>
              <Route exact path="/auth" component={Auth} />
              <Route exact path="/" component={AppHome} />
              <Route exact path="/history" component={AppParameterList} />

              <Route exact path="/result/:id" component={AppResultSingle} />
              <Route exact path="/form" component={AppParameterForm} />
              <Route exact path="/users" component={AppUsers} />
              <Route exact path="/profile" component={AppProfile} />
              <Redirect to="/" />
            </Switch>
          </Suspense2>
        </AuthWrapper>
        <div className="z-10 grow-0 border-0 border-solid border-slate-100 border-t-[1px] fixed left-0 shadow-black bottom-0 w-[100vw]">
          <AppBottomTabBar />
        </div>
      </BrowserRouter>
      <BackTop className="bottom-20" />
    </div>
  );
}

export default App;
