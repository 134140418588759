/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import type { AxiosInstance, AxiosError } from 'axios';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { History } from 'history';
import { flushUserCookies, getTokens } from '@/utils/login';
import isAccessibleRoute from '@/routes/routesPermission';

const baseURL = import.meta.env.VITE_SERVICE_BASE_URL;
nProgress.configure({
  minimum: 0.1,
  showSpinner: false
});

declare module 'axios' {
  export interface AxiosRequestConfig {
    // 其他的设置，用于在拦截器通信
    meta?: {
      // 是否不使用progress
      noProgress?: boolean;
    };
  }
}

const request = axios.create({
  baseURL: baseURL
});

const errHandler = (error: any) => {
  if (nProgress.isStarted()) {
    nProgress.done();
  }

  return Promise.reject(error);
};

const responseErrorHandler = ({ history }: { history: History }) => {
  return (err: AxiosError) => {
    if (nProgress.status) {
      nProgress.done();
    }
    if (err.status == 401) {
      if (!isAccessibleRoute()) {
        flushUserCookies();
        history.push('/auth');
      }
    }
    return Promise.reject(err);
  };
};

export function useInitAxios() {
  const history = useHistory<any>();
  useEffect(() => {
    const eject = setupInterceptors(request, history);
    return eject;
  }, [history]);
}

const responseInterceptor =
  (options: { history: History; flushUserCookies: () => void; isAccessibleRoute: () => boolean }) =>
  (v: AxiosResponse) => {
    const { history, flushUserCookies, isAccessibleRoute } = options;
    if (nProgress.status) {
      nProgress.done();
    }
    return v.data;
  };

type TGetTokens = typeof getTokens;
const requestInterceptor = (options: { getTokens: TGetTokens }) => (v: AxiosRequestConfig) => {
  const { getTokens } = options;
  const tokens = getTokens();
  if (tokens?.token) {
    v.headers['Authorization'] = `Token ${tokens.token}`;
  }
  if (!nProgress.isStarted() && !v.meta?.noProgress) {
    nProgress.start();
  }
  return v;
};

function setupInterceptors(request: AxiosInstance, history: History) {
  const id1 = request.interceptors.request.use(requestInterceptor({ getTokens }), errHandler);
  const id2 = request.interceptors.response.use(
    responseInterceptor({ history, flushUserCookies, isAccessibleRoute }),
    responseErrorHandler({ history })
  );
  return () => {
    request.interceptors.request.eject(id1);
    request.interceptors.response.eject(id2);
  };
}

export const exportedForTesting = {
  requestInterceptor,
  responseInterceptor
};

export default request;
