import AntdUpload from 'antd/es/upload';
import { useState } from 'react';
import { Button } from 'antd-mobile';
import { serviceUserBatchCreate } from '@/services/auth';
import { readUsersFromExcel } from '@/utils/excel';

export default function AppUsersUpload() {
  const [files, setFiles] = useState([]);
  return (
    <AntdUpload
      fileList={files}
      onChange={(v) => {
        setFiles(v.fileList);
      }}
      beforeUpload={async (file) => {
        const users = await readUsersFromExcel(file);
        await serviceUserBatchCreate(users);
      }}
    >
      <Button>批量导入</Button>
    </AntdUpload>
  );
}
