import React from 'react';
import $style from './AppBackground.module.less';
import { useStore } from '@/store/store';
import appBackgroundInner from '@/assets/app-background-inner.png';
import geFeiLogoWhite from '@/assets/gefei_logo_white.png';

export default function AppBackground() {
  const { pageInfo } = useStore({ keys: ['pageInfo'] });
  return (
    <div className={$style.background}>
      <img src={geFeiLogoWhite} className={$style.background__logo} />

      <div className={$style.background__text}>养老规划计算器</div>
      <img className={$style.background__inner} src={appBackgroundInner} />
    </div>
  );
}
