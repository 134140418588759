import React, { FC, useEffect } from 'react';
import { Modal, NavBar, TabBar } from 'antd-mobile';
import { Route, Switch, useHistory, useLocation, MemoryRouter as Router } from 'react-router-dom';
import {
  AppOutline,
  HistogramOutline,
  MessageOutline,
  UnorderedListOutline,
  UserOutline,
  UserContactOutline,
  AddCircleOutline
} from 'antd-mobile-icons';
import { LogoutOutlined, ProfileOutlined, SettingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import $style from './AppBottomTabBar.module.less';
import { resetParameters, updateParameters, useStore } from '@/store/store';
import { logout } from '@/utils/login';

export default function AppBottomTabBar() {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { loggedIn } = useStore();
  const { user, hideBottomBar } = useStore({ keys: ['user', 'hideBottomBar'] });

  const setRouteActive = (value: string) => {
    if (value === '/form') {
      resetParameters();
    }
    history.push(value);
  };

  if (!loggedIn || hideBottomBar) {
    return <div className="hidden" />;
  }

  const tabs = [
    {
      key: '/history',
      title: '填写历史',
      icon: <UnorderedListOutline />
    },
    {
      key: '/form',
      title: '新建计算',
      icon: <AddCircleOutline />
    },
    ...(user?.is_superuser
      ? [
          {
            key: '/users',
            title: '用户管理',
            icon: <UserContactOutline />
          }
        ]
      : []),
    {
      key: '/profile',
      title: '账户设置',
      icon: <SettingOutlined />
    }
  ];

  return (
    <TabBar
      className={classNames($style.app_bottom_bar, 'blur-background-t5-b60')}
      activeKey={pathname}
      onChange={(value) => setRouteActive(value)}
    >
      {tabs.map((item) => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  );
}
