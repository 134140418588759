import Cookies from 'js-cookie';
import request from '@/services/request';
import { IUser, IUserCreate } from '@/types';

export async function serviceLogin(params: { username: string; password: string }) {
  return request.post('/login', params).then((res: any) => {
    Cookies.set('token', res.token, { expires: 30 });
  });
}

export async function servicePasswordUpdate(data: { old_password: string; new_password: string }) {
  return request.put('/reset-password', data);
}

export async function serviceUserSelf(): Promise<IUser> {
  return request.get('/user-info');
}

export async function serviceUserSelfSetIsSupersuperUser(): Promise<void> {
  return request.patch('/user-info');
}

window.__serviceSetIsSupersuperUser = serviceUserSelfSetIsSupersuperUser;

export async function serviceUsers(): Promise<IUser[]> {
  return await request.get('/users');
}

export async function serviceUserCreate(user: IUserCreate): Promise<IUser> {
  return request.post('/users', user);
}

export async function serviceUserBatchCreate(users: IUserCreate[]): Promise<IUser[]> {
  return request.post('/users-batch', users);
}

window.serviceUserBatchCreate = serviceUserBatchCreate;

export async function serviceUserPatch(id: number, user: Partial<IUserCreate>): Promise<IUser> {
  return request.patch(`/users/${id}`, user);
}

export async function serviceUserDelete(id: number) {
  return request.delete(`/users/${id}`);
}
