import React from 'react';
import ReactDOM from 'react-dom';
import App from './components-views/App';
import './index.css';
import zhCN from 'antd/es/locale/zh_CN';
import LocaleProvider from 'antd/es/locale-provider';
import ConfigProvider from 'antd/es/config-provider';
import 'antd/dist/antd.css';

ReactDOM.render(
  <React.StrictMode>
    <LocaleProvider locale={zhCN}>
      <ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>
    </LocaleProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
