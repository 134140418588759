import { useDebounceFn, useRequest } from 'ahooks';
import { ColumnsType } from 'antd/es/table/interface';
import Table from 'antd/es/table';

import { Button, Form, Input, Modal, NavBar, Toast } from 'antd-mobile';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import AntButton from 'antd/es/button';
import { IUser } from '@/types';
import { goToFormPage, goToHistoryPage, goToLoginPage, goToResultPage } from '@/utils/navigations';
import { setPageInfo, updateParameters, useStore } from '@/store/store';
import { serviceUserCreate, serviceUserDelete, serviceUserPatch, serviceUsers } from '@/services/auth';
import { serviceParametersDelete } from '@/services/calculations';
import AppUsersUpload from '@/components-views/AppUsersUpload';

export default function AppUsers() {
  const { user, showBatchCreateUsers } = useStore();
  useEffect(() => {
    setPageInfo({
      title: '用户管理'
    });
  }, []);
  const {
    refresh: refreshUsers,
    data: users,
    loading,
    run
  } = useRequest(
    async () => {
      if (user) {
        return serviceUsers();
      } else {
        return [];
      }
    },
    {
      refreshDeps: [user]
    }
  );
  const filteredUsers = users?.filter((u) => u.id !== user.id);
  const [userToUpdate, setUserToUpdate] = useState<IUser | undefined>();
  const cols: ColumnsType<any> = [
    {
      title: '姓名',
      width: 60,
      dataIndex: 'last_name',
      key: 'last_name',
      fixed: 'left',
      sorter: (a, b) => (a > b ? -1 : 1)
    },
    {
      title: '用户名',
      width: 100,
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => (a > b ? -1 : 1)
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 45,
      render: (_, item) => (
        <div>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              setUserToUpdate(item);
              setUpdateModalVisible(true);
            }}
          >
            <EditOutlined />
          </Button>
          <Button
            className="mt-2"
            size="small"
            color="danger"
            onClick={() => {
              Modal.confirm({
                title: '删除用户',
                content: `用户名：${item.username}`,
                confirmText: '确定删除',
                closeOnMaskClick: true,
                async onConfirm() {
                  await serviceUserDelete(item.id);
                  run();
                }
              });
            }}
          >
            <DeleteOutlined />
          </Button>
        </div>
      )
    }
  ];
  const [modalVisible, setModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const submit = useDebounceFn(
    async (v) => {
      try {
        setSubmitting(true);
        if (v.id) {
          await serviceUserPatch(v.id, v);
          refreshUsers();
          Toast.show({
            content: <span className="">修改成功</span>
          });
        } else {
          await serviceUserCreate(v);
          refreshUsers();
          Toast.show({
            content: <span className="">创建成功</span>
          });
        }

        setModalVisible(false);
      } catch (e) {
        Toast.show({
          content: <span className="text-red-200">创建失败</span>
        });
      } finally {
        setSubmitting(false);
      }
    },
    { wait: 500, leading: true }
  );

  const submitPatch = (v: any) => {
    submit.run({ ...v, id: userToUpdate.id });
  };
  if (user && !user.is_superuser) {
    goToHistoryPage();
    return <div />;
  }
  return (
    <div className="page_content">
      <Modal
        title="新增用户"
        closeOnMaskClick
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
        content={
          <Form
            onFinish={submit.run}
            footer={
              <div className="w-full flex justify-center">
                <Button color="primary" type="submit" loading={submitting}>
                  新建
                </Button>
                <Button
                  className="ml-2"
                  onClick={() => {
                    setModalVisible(false);
                  }}
                >
                  取消
                </Button>
              </div>
            }
          >
            <Form.Item
              name="username"
              required
              rules={[
                {
                  min: 8,
                  max: 32
                },
                {
                  pattern: /^[a-zA-Z0-9_-]{8,32}$/,
                  message: '用户名仅允许字母，数字，下划线，减号'
                }
              ]}
              label="用户名"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="last_name"
              required
              rules={[
                {
                  min: 1,
                  max: 16,
                  whitespace: false
                }
              ]}
              label="姓名"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              required
              rules={[
                {
                  min: 8,
                  max: 32,
                  whitespace: false
                },
                {
                  pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,32}$/,
                  message: '至少八个字符，至少一个字母和一个数字'
                }
              ]}
              label="密码"
            >
              <Input type="password" />
            </Form.Item>
          </Form>
        }
      />
      <Modal
        title="修改用户"
        closeOnMaskClick
        visible={updateModalVisible}
        onClose={() => {
          setUpdateModalVisible(false);
        }}
        content={
          userToUpdate ? (
            <Form
              key={userToUpdate.id}
              onFinish={submitPatch}
              initialValues={userToUpdate}
              footer={
                <div className="w-full flex justify-center">
                  <Button color="primary" type="submit" loading={submitting}>
                    确认修改
                  </Button>
                  <Button
                    className="ml-2"
                    onClick={() => {
                      setUpdateModalVisible(false);
                    }}
                  >
                    取消
                  </Button>
                </div>
              }
            >
              <Form.Item
                name="username"
                required
                rules={[
                  {
                    min: 8,
                    max: 32
                  },
                  {
                    pattern: /^[a-zA-Z0-9_-]{8,32}$/,
                    message: '用户名仅允许字母，数字，下划线，减号'
                  }
                ]}
                label="用户名"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="last_name"
                required
                rules={[
                  {
                    min: 1,
                    max: 10,
                    whitespace: false
                  }
                ]}
                label="姓名"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                required
                rules={[
                  {
                    min: 8,
                    max: 32,
                    whitespace: false
                  },
                  {
                    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,32}$/,
                    message: '至少八个字符，至少一个字母和一个数字'
                  }
                ]}
                label="新密码"
              >
                <Input type="password" />
              </Form.Item>
            </Form>
          ) : null
        }
      />
      <div className="flex justify-end blur-background-light">
        <AntButton
          type="text"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVisible(true);
          }}
        >
          添加新用户
        </AntButton>
        {showBatchCreateUsers && <AppUsersUpload />}
      </div>
      <Table
        className="pb-20"
        sticky
        key={filteredUsers?.length}
        loading={loading}
        rowKey="id"
        pagination={false}
        columns={cols}
        dataSource={filteredUsers}
      />
    </div>
  );
}
